import React from 'react';
import styled from 'styled-components';

import { REACT_GRAY } from '../../constants';

// =============================================================================
// Styled Components
// =============================================================================

const StyledMain = styled.main`
  padding: 20px;
  height: 100vh;
  background-color: ${REACT_GRAY};
`;

// =============================================================================
// Main Component
// =============================================================================
// TODO: @PHANTOM-TEAM: Let's improve this UI

const NoProvider = () => {
  const tg = window.Telegram.WebApp;

  const handleAddWallet = () => {
    if (tg) {
      tg.openLink('https://phantom.app/');
    } else {
      console.error('Telegram WebApp API is not available.');
    }
  };

  return (
    <StyledMain>
      <h2>Could not find a provider</h2>
      <div onClick={handleAddWallet}>Add PHANTOM</div>
    </StyledMain>
  );
};

export default NoProvider;
